const appData = {
  "appDomain": "new-patients.yaletowndentistry.com",
  "appUrl": "https://new-patients.yaletowndentistry.com",
  "publicUrl": "http://0.0.0.0:3000",
  "publicRoutePath": "http://0.0.0.0:3000",
  "gseoClient": "yaletowndentistry",
  "gseoRecaptcha": "6LfSxMMaAAAAAGziyyYOEc7MosJoqW8j0nj2j0dP",
  "analyticsUA": "UA-55573302-1",
  "ga4_id": "G-2E0995L5BZ"
};
export {appData};
