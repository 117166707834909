import React, { useEffect, lazy, Suspense } from "react";
import ReactGA from "react-ga4";
import { appData } from "./data/config";

const GSEO = lazy(() => import("./sites/gseo/Gseo"));
const GSEODev = lazy(() => import("./sites/gseo-dev/GseoDev"));
const GSEOHosting = lazy(() => import("./sites/gseo-hosting/GseoHosting"));
const GSEOHours = lazy(() => import("./sites/gseo-hours/GseoHours"));
const GSEOHoursDecline = lazy(() =>
	import("./sites/gseo-hours-decline/GseoHours")
);
const Cleaning4U = lazy(() => import("./sites/cleaning4u/Cleaning4U"));
const YaletownDentistry = lazy(() =>
	import("./sites/yaletowndentistry/YaletownDentistry")
);

const API_KEYS = {
	RECAPTCHA: appData.gseoRecaptcha,
};

const API_ENDPOINTS = {};
if (process.env.NODE_ENV === "production") {
	// API_ENDPOINTS.test = `${appData.appUrl}/gseo-form-server/get/data`;
	// API_ENDPOINTS.mailer = `${appData.appUrl}/gseo-form-server/send/form_email`;
	// API_ENDPOINTS.recaptcha = `${appData.appUrl}/gseo-form-server/verify/recaptcha`;
	API_ENDPOINTS.test = "https://lead-form-api.guaranteedseo.com/get/data";
	API_ENDPOINTS.mailer =
		"https://lead-form-api.guaranteedseo.com/send/form_email";
	API_ENDPOINTS.recaptcha =
		"https://lead-form-api.guaranteedseo.com/verify/recaptcha";
} else {
	API_ENDPOINTS.test = "http://localhost:5055/get/data";
	API_ENDPOINTS.mailer = "http://localhost:5055/send/form_email";
	API_ENDPOINTS.recaptcha = "http://localhost:5055/verify/recaptcha";
}

const queryParams = new URLSearchParams(window.location.search);

if (appData.ga4_id) {
	ReactGA.initialize(appData.ga4_id, {
		debug: false,
	});
}

const sendGAView = (page) => {
	ReactGA.send("pageview");
	ReactGA.event("lead_form1");
};

const sendGAEvent = (event_name) => {
	ReactGA.event(event_name);
};

const MainLoader = <div className="p-10 text-4xl text-center">Loading...</div>;

const App = () => {
	const getActiveSite = (v) => {
		if (v === "gseolive") {
			return (
				<Suspense fallback={MainLoader}>
					<GSEO
						API_KEYS={API_KEYS}
						API_ENDPOINTS={API_ENDPOINTS}
						sendGAView={sendGAView}
						sendGAEvent={sendGAEvent}
						queryParams={queryParams}
					/>
				</Suspense>
			);
		} else if (v === "gseodev") {
			return (
				<Suspense fallback={MainLoader}>
					<GSEODev
						API_KEYS={API_KEYS}
						API_ENDPOINTS={API_ENDPOINTS}
						sendGAView={sendGAView}
						sendGAEvent={sendGAEvent}
						queryParams={queryParams}
					/>
				</Suspense>
			);
		} else if (v === "gseo-hosting") {
			return (
				<Suspense fallback={MainLoader}>
					<GSEOHosting
						API_KEYS={API_KEYS}
						API_ENDPOINTS={API_ENDPOINTS}
						sendGAView={sendGAView}
						sendGAEvent={sendGAEvent}
						queryParams={queryParams}
					/>
				</Suspense>
			);
		} else if (v === "cleaning4u") {
			return (
				<Suspense fallback={MainLoader}>
					<Cleaning4U
						API_KEYS={API_KEYS}
						API_ENDPOINTS={API_ENDPOINTS}
						sendGAView={sendGAView}
						sendGAEvent={sendGAEvent}
						queryParams={queryParams}
					/>
				</Suspense>
			);
		} else if (v === "yaletowndentistry") {
			return (
				<Suspense fallback={MainLoader}>
					<YaletownDentistry
						API_KEYS={API_KEYS}
						API_ENDPOINTS={API_ENDPOINTS}
						sendGAView={sendGAView}
						sendGAEvent={sendGAEvent}
						queryParams={queryParams}
					/>
				</Suspense>
			);
		} else if (v === "gseo-hours") {
			return (
				<Suspense fallback={MainLoader}>
					<GSEOHours
						API_KEYS={API_KEYS}
						API_ENDPOINTS={API_ENDPOINTS}
						sendGAView={sendGAView}
						sendGAEvent={sendGAEvent}
						queryParams={queryParams}
					/>
				</Suspense>
			);
		} else if (v === "gseo-hours-decline") {
			return (
				<Suspense fallback={MainLoader}>
					<GSEOHoursDecline
						API_KEYS={API_KEYS}
						API_ENDPOINTS={API_ENDPOINTS}
						sendGAView={sendGAView}
						sendGAEvent={sendGAEvent}
						queryParams={queryParams}
					/>
				</Suspense>
			);
		} else {
			return (
				<Suspense fallback={MainLoader}>
					<GSEODev
						API_KEYS={API_KEYS}
						API_ENDPOINTS={API_ENDPOINTS}
						sendGAView={sendGAView}
						sendGAEvent={sendGAEvent}
						queryParams={queryParams}
					/>
				</Suspense>
			);
		}
	};

	useEffect(() => {
		const loadScriptByURL = (id, url, callback) => {
			const isScriptExist = document.getElementById(id);
			if (!isScriptExist) {
				var script = document.createElement("script");
				script.type = "text/javascript";
				script.src = url;
				script.id = id;
				script.onload = function () {
					if (callback) callback();
				};
				document.body.appendChild(script);
			}

			if (isScriptExist && callback) callback();
		};
		loadScriptByURL(
			"recaptcha-key",
			`https://www.google.com/recaptcha/api.js?render=${API_KEYS.RECAPTCHA}`,
			function () {
				console.log("Recaptcha Script loaded!");
			}
		);
	}, []); // [] here = empty dependancies so it only loads once?? not 100%

	return <div>{getActiveSite(appData.gseoClient)}</div>;
};

export default App;
